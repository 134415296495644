@font-face {
    font-family: "TTInterfaces";
    src: url("../public/fonts/TTInterfaces-Regular.woff") format("truetype");
}

html {
    width: 100%;
}

body {
    width: 100%;
    margin: 0;
    font-family: "TTInterfaces", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f6f6f6;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

* {
    box-sizing: border-box;
}

.animate {
    opacity: 0;
    animation: fade-in-up 1s ease-in-out both;
}

@keyframes fade-in-up {
    from {
        opacity: 0;
        transform: translateY(20px);
        /* Adjust the vertical distance as needed */
    }

    to {
        opacity: 1;
        transform: translateY(0);
        /* Adjust the vertical distance as needed */
    }
}

/* section {
  overflow-x: hidden;
} */

h2 {
    line-height: 95% !important;
}

@media screen and (max-width: 728px) {
    h2 {
        line-height: 45px !important;
    }
}
