#success {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

#success .text {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#success h1 {
    font-weight: 600;
    font-size: 64px;
    line-height: 93%;
    letter-spacing: -4px;
    color: #E76B47;
    margin: 0;
}

#success h2 {
    font-weight: 400;
    font-size: 36px;
    line-height: 93%;
    letter-spacing: -2px;
    color: #3A3A3E;
    margin: 0;
    margin-bottom: 20px;
}

#success p {
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #3A3A3E;
    margin: 0;
    padding: 0 20px;
}

#success button {
    margin-top: 40px;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #E76B47;
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    cursor: pointer;
}

#success .icon {
    margin-bottom: 40px;
}

#success .bg {
    position: absolute;
    height: 100%;
    right: 0;
    z-index: -1;
}