#notFound {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#notFound .text {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#notFound h1 {
    font-weight: 700;
    font-size: 300px;
    line-height: 93%;
    letter-spacing: -33.0025px;
    color: #E76B47;
    margin: 0;
}

#notFound h2 {
    font-weight: 600;
    font-size: 64px;
    line-height: 93%;
    letter-spacing: -4px;
    color: #3A3A3E;
    margin: 0;
    margin-bottom: 10px;
}

#notFound p {
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #3A3A3E;
    padding: 0 40px;
    margin: 0;
}

#notFound button {
    margin-top: 40px;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #E76B47;
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    #notFound .text {
        width: 100%;
    }
    #notFound h1 {
        font-size: 150px;
        letter-spacing: -15px;
    }
    #notFound h2 {
        font-size: 32px;
        letter-spacing: -2px;
    }
}