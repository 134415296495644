#progress {
    margin-bottom: 150px;
    .inner {
        width: 80%;
        margin: 0 auto;

        h2 {
            font-weight: 600;
            font-size: 64px;
            line-height: 95%;
            letter-spacing: -4px;
            background: linear-gradient(
                288.47deg,
                #3a3a3e 28.49%,
                #b9b9be 106.94%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }

    .progress {
        &-wrapper {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
        }

        &-item {
            width: calc(50% - 10px);
            &-poster {
                width: 100%;
                cursor: pointer;
                transition: all 0.4s ease-in-out;
                position: relative;
                &:hover {
                    img {
                        transition: all 0.4s ease-in-out;
                        filter: brightness(40%);
                    }
                    &:after {
                        transition: all 0.4s ease-in-out;
                        content: " ";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: block;
                        width: 50px;
                        height: 50px;
                        background-image: url("../../svg/play.svg");
                        z-index: 9;
                    }
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            &-bottom {
                margin-top: 20px;
                display: flex;
                align-items: center;
                gap: 50px;
            }

            &-date {
                color: #3a3a3e;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; /* 19.2px */
            }

            &-play {
                display: flex;
                align-items: center;
                gap: 12px;
                font-size: 16px;
                color: #c95c3e;
                cursor: pointer;
            }
        }

        &-modal {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            align-items: center;
            justify-content: center;
            z-index: 99;
            background: rgba(0, 0, 0, 0.5);

            &-wrapper {
                max-width: 800px;
                width: 100%;
                height: 450px;
                margin: 0 20px;
                iframe {
                    width: 100%;
                    height: 100%;
                }
            }

            &.active {
                display: flex;
            }
        }
    }
}

@media (min-width: 1281px) {
    #progress {
        .inner {
            width: 70%;
        }
    }
}

@media (max-width: 900px) {
    #progress {
        .progress {
            &-item {
                width: 100%;
            }
        }
    }
}

@media (max-width: 728px) {
    #progress {
        .inner {
            width: 100%;
            padding: 0 20px;

            h2 {
                font-size: 36px;
            }
        }
        .progress {
            &-item {
                width: 100%;
            }
        }
    }
}

@media (max-width: 576px) {
    #progress {
        .progress {
            &-modal {
                &-wrapper {
                    height: 300px;
                }
            }
        }
    }
}
