footer {
    background-color: #3a3a3e;
}

footer .inner {
    width: 80%;
    margin: 0 auto;
    padding: 80px 0;
}

footer .title {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
}

footer .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 70px;
}

footer .logo {
    width: 15%;
}

footer .phone {
    font-weight: 600;
    font-size: 24px;
    line-height: 0.95;
    letter-spacing: -1px;
    color: #e76b47;
    text-decoration: none;
}

footer .adresses {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 50px;
    gap: 10px;
}

footer .adress span {
    display: block;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    color: #f6f6f6;
    padding-bottom: 10px;
    text-decoration: none;
}

footer .adress a {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #e76b47;
    text-decoration: none;
}

footer .inner > p {
    margin: 0;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    color: #f6f6f6;
}

@media screen and (min-width: 1281px) {
    footer .inner {
        width: 70%;
    }
}

@media screen and (max-width: 728px) {
    footer .inner {
        width: 100%;
        padding: 80px 20px;
    }

    footer .info {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 50px;
        padding-bottom: 50px;
    }

    footer .adresses {
        flex-wrap: wrap;
    }

    footer .logo {
        width: 50%;
    }
}
