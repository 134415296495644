#panorama {
    width: 100%;
    padding-bottom: 100px;
}

#panorama .inner {
    width: 80%;
    margin: 0 auto;
}

#panorama h2 {
    font-weight: 600;
    font-size: 64px;
    line-height: 95%;
    letter-spacing: -4px;
    background: -webkit-linear-gradient(288.47deg, #3A3A3E 28.49%, #B9B9BE 106.94%);
    background: linear-gradient(288.47deg, #3A3A3E 28.49%, #B9B9BE 106.94%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

#panorama .panorama {
    width: 100%;
    height: 720px;
}

@media screen and (min-width: 1281px) {
    #panorama .inner {
        width: 70%;
    }
}

@media screen and (max-width: 728px) {
    #panorama .inner {
        width: 100%;
    }

    #panorama h2 {
        padding: 0 20px;
        font-weight: 600;
        font-size: 36px;
        line-height: 0.95;
        letter-spacing: -2px;
    }

    #panorama .panorama {
        height: 300px;
    }
}