header {
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    position: fixed;
    display: flex;
    align-items: center;
    background-color: #F6F6F6;
    z-index: 100;
    /* border: 1px solid red; */
}

header .inner {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    padding: 8px 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f6f6f6;
    /* border: 1px solid red; */
}

header .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .logo {
    width: 142px;
    margin-bottom: 23px;
}

nav {
    display: flex;
    gap: 20px;

}

nav>a {
    font-weight: 300;
    font-size: 16px;
    color: #3A3A3E;
    text-decoration: none;
}

nav>a:hover {
    font-weight: 600;
    color: #E76B47;
}

header .right {
    font-weight: 600;
    font-size: 16px;
}

header .menu-icon {
    display: none;
}

header .menu-icon {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 18px;
    height: 12px;
    cursor: pointer;
}

header .line {
    width: 100%;
    height: 2px;
    background-color: #000;
    transition: transform 0.3s, opacity 0.3s;
}

header .menu-icon .line:nth-child(2) {
    width: 60%;
}

header .menu-icon.open .line:nth-child(1) {
    transform: rotate(45deg) translate(1px, 6px);
}

header .menu-icon.open .line:nth-child(2) {
    opacity: 0;
}

header .menu-icon.open .line:nth-child(3) {
    transform: rotate(-45deg) translate(1px, -6px);
}

.overlay {
    position: fixed;
    top: 90px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
}

header nav.drop-down {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F6F6F6;
    top: -300px;
    left: 0;
    transition: 0.8s;
    z-index: 5;
    padding: 0px 0 20px 0;
}

header nav.drop-down.open {
    top: 89px;
    /* transform: translateY(300px); */
}

header nav.drop-down a, header nav.drop-down span {
    font-size: 16px;
}

header nav.drop-down span a{
    text-decoration: none;
    color: #3A3A3E;
}

header nav.drop-down span {
    margin-top: 20px;
}

header .right a {
    text-decoration: none;
    color: #3A3A3E;
}

header nav.drop-down>* {
    text-align: center;
}

header .media {
    display: none;
}

@media screen and (max-width: 1040px) {
    header nav a {
        font-size: 12px;
    }

    header span {
        font-size: 12px;
    }

    header nav {
        gap: 10px;
    }
}

@media screen and (max-width: 768px) {
    header nav {
        display: none;
    }

    header .right span {
        display: none;
    }

    header .menu-icon {
        display: flex;
    }
}

@media screen and (min-width: 1281px) {
    header .inner {
        padding: 8px 15%;
    }
}